.backdrop {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px;

  display: flex;
  justify-content: center;

  z-index: 9999999999;
}

.modal {
  width: 750px;
  max-width: 90%;
  height: min-content;
  min-height: 440px;
  color: #111111;
  background-color: #FFFFFF;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.header {
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding: 0 15px;
}

.headerLeft {
  display: flex;
  flex-direction: column;
}

.website {
  font-size: 16px;
  line-height: 1.5;
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
}

.close {
  padding: 16px;
  color: #666666;
  font-size: 16px;
  line-height: 24px;
}

.body {
  display: flex;
}

.menu {
  color: #666666;
  background-color: #E6E6E6;
  font-family: Arial,sans-serif;
  width: 150px;
  min-width: 150px;
}

.item {
  padding: 10px 5px 10px 10px;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  border-bottom: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
  width: 100%;
  text-align: left;
}

.item:focus {
  box-shadow: 0 0 0 2px #FBFBFB;
  border-radius: 2px;
  border-bottom: 1px solid #FBFBFB;
  border-right: 1px solid #FBFBFB;
}

.content {
  padding: 25px;
  display: flex;
  flex-direction: column;
}

.content .title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 16px;
}

.content .description {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-line;
  margin-bottom: 16px;
}

.toggle {
  position: relative;
}

.switchOn::before {
  background: #55D069;

  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 2px;
  width: 40px;
  height: 24px;
  border-radius: 16px;
  border: 1px solid #D9D9D9;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
}

.switchOn::after {
  content: "";
  position: absolute;
  display: block;
  left: 16px;
  top: 2px;
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
}

.switchOff::before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 2px;
  width: 40px;
  height: 24px;
  border-radius: 16px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
}

.switchOff::after {
  content: "";
  position: absolute;
  display: block;
  left: 0px;
  top: 2px;
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  -webkit-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
}

.label {
  margin-left: 50px;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
}

.footer {
  background-color: #F2F2F2;
  border-top: 1px solid #CCCCCC;
  display: flex;
  justify-content: flex-end;
  padding: 12px;
}

.confirm {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #FFFFFF;
  background-color: #0099CC;
}

@media screen and (max-width: 768px) {
  .modal {
    max-height: 90%;
    overflow: auto;
  }

  .content {
    padding: 15px;
  }
}