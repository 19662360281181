.cookieConsent {
  position: fixed;
  bottom: 0;
  right: 0;
  max-width: 100%;
  padding: 48px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  background-color: #f2f2f2;
  color: #111111;

  z-index: 2147483647;

  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.content {
  font-size: 16px;
  line-height: 24px;

  margin-bottom: 4px;
}

.buttons {
  display: flex;
  gap: 4px;

  margin-bottom: 4px;
  flex-wrap: wrap;
}

.acceptAll {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #FFFFFF;
  background-color: #0099CC;
}

.rejectAll {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #FFFFFF;
  background-color: #0099CC;
}

.configure {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  background-color: #EAEAEA;
}

@media screen and (max-width: 768px) {
  .cookieConsent {
    overflow: auto;
    /* height: 200px; */
    max-height: 50%;
    max-width: 100%;
  }

  .buttons {
    flex-direction: column;
  }

  .buttons > button {
    flex: 1
  }
}