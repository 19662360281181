.footer {
  background-color: var(--blue-400);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
}

.footer ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  list-style: none;
}

.footer a {
  color: var(--color-light);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer a:hover {
  color: #ddd;
}

.footer svg {
  height: 1rem;
  width: 1rem;
  fill: var(--color-light);
}

.footer a:hover svg {
  fill: #ddd;
}

@media screen and (max-width: 768px) {
  .footer {
    height: 90px;
  }

  .footer ul {
    /* gap: 0.5rem; */
    gap: 30px;
    justify-content: space-around;
    padding: 0 15px;
  }

  /* .noPhone {
    display: none;
  } */
}
