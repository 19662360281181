.header {
  background-color: var(--blue-400);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 3.5rem; */
  height: 2.5rem;
  padding-left: 1rem;

  transition: 0.3s all;
}

.header nav {
  display: flex;
  height: 100%;
}

.header nav > a,
.header nav button {
  color: var(--color-light);
  font-size: 0.8rem;
  /* font-weight: 600; */
  height: 100%;
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* .header nav > a:hover,
.header nav button:hover {
  background-color: var(--blue-300);
} */

.header nav > a svg,
.header nav button svg {
  fill: var(--color-light);
  height: 1rem;
  width: 1rem;
}

.header ul {
  list-style: none;
}

.header .logoSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* gap: 2rem; */
  gap: 1rem;
}

/* TODO: remove to enable language selection */
.header .flags {
  display: none;
}

.header .flags svg {
  margin-inline: 0.25rem;
  width: 1.5rem;
}

.burger {
  display: none;
}

.burger > button {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 9px 10px;
  /* margin-top: 3px; */
  margin-top: -1px;
  margin-bottom: 3px;
  background-color: transparent;
  background-image: none;
  border: 1px solid #dddddd;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: self-start; */
  height: 34px;
}

.burger > button:focus {
  background-color: #dddddd;
}

.burger > button > span {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
  background-color: #888888;
}

.back {
  color: #ffffff;
  fill: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.chevron {
  display: inline-block;
  width: 10px;
  /* fill: #FFFFFF; */
  margin-top: 2px;
  margin-right: 2px;
}

.headerDropdown {
  height: 100%;
  position: relative;
}

.headerDropdown button.cta {
  background-color: var(--green-400);
}

.headerDropdown button.cta:hover {
  background-color: var(--green-300);
}

@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }

  .header .logoSection {
    width: 100%;
    margin-top: 5px;
    padding-left: 1rem;
  }

  .header nav {
    /* display: none; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    align-self: stretch;
    overflow: hidden;
  }

  .header nav > a,
  .header nav button {
    height: auto;
    width: 100%;
    align-self: stretch;
    /* padding: 0.5rem 1rem; */
    padding: 10px 1rem;
    line-height: 20px;
    font-size: 14px;
  }

  .headerDropdownWrapper {
    width: 100%;
    align-self: stretch;
  }

  /* .dropdown {
    background-color: var(--blue-400);
  } */

  .expanded {
    /* height: 11rem; */
    height: auto;
  }

  .burger {
    display: block;
  }
}
