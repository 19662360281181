.headerDropdown {
  height: 100%;
  position: relative;
}

.headerDropdown .dropdown {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  white-space: nowrap;
  width: auto;
  z-index: 10;
  position: absolute;
  /* top: 3.5rem; */
  top: 2.5rem;
  right: 0;
}

.headerDropdown .dropdown li a,
.headerDropdown .dropdown li button {
  color: #000;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  /* padding: 0.5rem 1rem; */
  /* padding: 10px 1rem; */
  padding: 5px 1rem;
  width: 100%;
}

.headerDropdown li a:hover,
.headerDropdown .dropdown li button:hover {
  background-color: #eee;
}

.headerDropdown button.cta {
  background-color: var(--green-400);
}

.headerDropdown button.cta:hover {
  background-color: var(--green-300);
}

.headerDropdown .dropdown li a svg,
.headerDropdown .dropdown li button svg {
  fill: #000;
  height: 1rem;
  width: 1rem;
}

.dropdown > ul {
  padding: 0;
  margin: 5px 0;
}

@media screen and (max-width: 768px) {
  .headerDropdown .dropdown {
    position: static;
  }

  .headerDropdown .dropdown li a,
  .headerDropdown .dropdown li button {
    padding: 10px 1rem;
  }
}