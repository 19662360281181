/* @import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,700); */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,500,600,700);

:root {
  --blue-300: #0086b2;
  --blue-400: #0099cc;
  --green-300: #449c44;
  --green-400: #5cb85c;
  --color-light: #fff;
  --color-gray: #e2e8f0;
  --color-border: #ccc;
}

/* *,
*::before,
*::after {
  box-sizing: border-box;
  font-weight: 400;
  margin: 0;
  padding: 0;
  position: relative;
} */

*,
*::before,
*::after {
  box-sizing: border-box;
  font-weight: 400;
}

/* TODO: fix this */
b > *,
strong > * {
  font-weight: bold;
}

body {
  margin: 0;
  padding: 0;

  font-family: sans-serif;
  font-size: 1rem;
}

/* ul {
  list-style: none;
} */

main {
  display: flex;
  /* min-height: calc(100vh - 6rem); */
  min-height: calc(100vh - 5rem);
}

button {
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
}

a {
  text-decoration: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gm-ui-hover-effect {
  outline: none;
}

b,
strong {
  font-weight: bold;
}

th {
  text-align: start;
}

.img-responsive {
  width: 100%;
  height: auto;
}

/* TODO: refactoring */
/* input:invalid {
  border: 1px solid red !important;
} */

@media screen and (max-width: 768px) {
  /* nav {
    display: none;
  } */

  main {
    flex-direction: column;
    align-items: center;
  }
}
